import { NgRxAction } from '../../../store/ngrx-action';

export enum DataSharedServicesActions {
    CADASTRO_CENTRO_DE_CUSTO_RECARREGAR = '[Data Shared Services] CADASTRO Centro de Custo',
    CADASTRO_FILIAL_RECARREGAR = '[Data Shared Services] CADASTRO Filial',
    CADASTRO_COLABORADOR_RECARREGAR = '[Data Shared Services] CADASTRO Colaborador',
    CADASTRO_FORNECEDOR_RECARREGAR = '[Data Shared Services] CADASTRO Fornecedor',
    CADASTRO_TIPO_FORNECEDOR_RECARREGAR = '[Data Shared Services] CADASTRO Tipo de Fornecedor',
    CADASTRO_TIPO_CONTRATO_RECARREGAR = '[Data Shared Services] CADASTRO Tipo de Contrato',
    CADASTRO_FORNECEDOR_CONTRATO_RECARREGAR = '[Data Shared Services] CADASTRO Fornecedor Contrato',
    CADASTRO_PRODUTO_RECARREGAR = '[Data Shared Services] CADASTRO Produto',
    CADASTRO_PROJETO_RECARREGAR = '[Data Shared Services] CADASTRO Projeto',
    CADASTRO_GRUPO_PRODUTO_RECARREGAR = '[Data Shared Services] CADASTRO Grupo de Produto',
    CADASTRO_CATEGORIA_PRODUTO_RECARREGAR = '[Data Shared Services] CADASTRO Categoria de Produto',
    CADASTRO_TIPO_PRODUTO_RECARREGAR = '[Data Shared Services] CADASTRO Tipo de Produto',
    CADASTRO_CONDICAO_DE_PAGAMENTO_RECARREGAR = '[Data Shared Services] CADASTRO Condição de Pagamento',
    CADASTRO_CONDICAO_DE_PAGAMENTO_ITEM_RECARREGAR = '[Data Shared Services] CADASTRO Condição de Pagamento Item',
    CADASTRO_MAPEAMENTO_DE_RATEIO_RECARREGAR = '[Data Shared Services] CADASTRO Mapeamento de Rateio',
    CADASTRO_MAPEAMENTO_DE_RATEIO_ITEM_RECARREGAR = '[Data Shared Services] CADASTRO Mapeamento de Rateio Item',

    COMPRAS_TIPO_DE_PAGAMENTO_RECARREGAR = '[Data Shared Services] COMPRAS Forma de Pagamento',
    COMPRAS_REGRA_DE_APROVACAO_RECARREGAR = '[Data Shared Services] COMPRAS Regra de Aprovacao',
    COMPRAS_TIPO_DE_COMPRA_RECARREGAR = '[Data Shared Services] COMPRAS Tipo de Compra',
    COMPRAS_REQUISICAO_ABERTAS_RECARREGAR = '[Data Shared Services] COMPRAS Requisicao Abertas',
    COMPRAS_ORDEM_ABERTAS_RECARREGAR = '[Data Shared Services] COMPRAS Ordem Abertas',
    COMPRAS_ORDEM_ENCERRADAS_RECARREGAR = '[Data Shared Services] COMPRAS Ordem Encerradas',
    COMPRAS_COTACOES_RECARREGAR = '[Data Shared Services] COMPRAS Cotacoes',
    COMPRAS_PEDIDO_ABERTOS_RECARREGAR = '[Data Shared Services] COMPRAS Pedidos Abertos',
    COMPRAS_PEDIDO_ENCERRADOS_RECARREGAR = '[Data Shared Services] COMPRAS Pedidos Encerrados',
    COMPRAS_RECEBIMENTO_PENDENTES_RECARREGAR = '[Data Shared Services] COMPRAS Recebimento Pendentes',
    COMPRAS_RECEBIMENTO_PROCESSADOS_RECARREGAR = '[Data Shared Services] COMPRAS Recebimento Processados',
    COMPRAS_ORDEM_SUGESTOES_RECARREGAR = '[Data Shared Services] COMPRAS Ordem Sugestoes',
    COMPRAS_ORDEM_PRODUTOS_RECARREGAR = '[Data Shared Services] COMPRAS Ordem Produtos',
    COMPRAS_PLANEJAMENTO_RECARREGAR = '[Data Shared Services] COMPRAS Planejamento',
    COMPRAS_MOTIVO_RECARREGAR = '[Data Shared Services] COMPRAS Motivo',
    COMPRAS_NIVEL_DE_APROVACAO_RECARREGAR = '[Data Shared Services] COMPRAS Nivel de Aprovacao',

    ESTOQUE_ALMOXARIFADO_RECARREGAR = '[Data Shared Services] ESTOQUE Almoxarifado',
    ESTOQUE_MAPEAMENTO_RECARREGAR = '[Data Shared Services] ESTOQUE Mapeamento',
    ESTOQUE_SECAO_RECARREGAR = '[Data Shared Services] ESTOQUE Seção',
    ESTOQUE_CLASSIF_AUX_RECARREGAR = '[Data Shared Services] ESTOQUE Classificacao Auxiliar',
    ESTOQUE_ENTRADA_RECARREGAR = '[Data Shared Services] ESTOQUE Entrada',
    ESTOQUE_SAIDA_RECARREGAR = '[Data Shared Services] ESTOQUE Saida',
    ESTOQUE_TRANSFERENCIA_RECARREGAR = '[Data Shared Services] ESTOQUE Transferencia',
    ESTOQUE_SALDOS_RECARREGAR = '[Data Shared Services] ESTOQUE Saldos',
    ESTOQUE_EXPEDICAO_PENDENTES_RECARREGAR = '[Data Shared Services] ESTOQUE Expedição Pendentes',
    ESTOQUE_EXPEDICAO_PROCESSADOS_RECARREGAR = '[Data Shared Services] ESTOQUE Expedição Processados',
    CADASTRO_EXPORTACAO_DE_RELATORIO_RECARREGAR = '[Data Shared Services] CADASTRO Exportação de Relatório',
    CADASTRO_EXPORTACAO_DE_RELATORIO_ITEM_RECARREGAR = '[Data Shared Services] CADASTRO Exportação de Relatório Item',
    ESTOQUE_MOTIVO_RECARREGAR = '[Data Shared Services] ESTOQUE Motivo',
    ESTOQUE_TIPO_DE_REQUISICAO_RECARREGAR = '[Data Shared Services] ESTOQUE Tipo de Requisição',
    ESTOQUE_NIVEL_DE_APROVACAO_RECARREGAR = '[Data Shared Services] ESTOQUE Nivel de Aprovacao',
    ESTOQUE_REGRA_DE_APROVACAO_RECARREGAR = '[Data Shared Services] ESTOQUE Regra de Aprovacao',

    CRM_CLIENTE_RECARREGAR = '[Data Shared Services] CRM Cliente',
    CRM_CATEGORIA_DE_CLIENTE_RECARREGAR = '[Data Shared Services] CRM Categoria de Cliente',
    CRM_TIPO_DE_CLIENTE_RECARREGAR = '[Data Shared Services] CRM Tipo de Cliente',
    CRM_GRUPO_DE_CLIENTE_RECARREGAR = '[Data Shared Services] CRM Grupo de Cliente',
    CRM_SITUACAO_DE_CLIENTE_RECARREGAR = '[Data Shared Services] CRM Situação de Cliente',

    FINANCEIRO_TIPO_DE_PAGAMENTO_RECARREGAR = '[Data Shared Services] FINANCEIRO Forma de Pagamento',
    FINANCEIRO_CLASSIFICACAO_AUXILIAR_RECARREGAR = '[Data Shared Services] FINANCEIRO Classificacao Auxiliar',
    FINANCEIRO_AGENCIA_RECARREGAR = '[Data Shared Services] FINANCEIRO Agencia',
    FINANCEIRO_CUSTO_ABC_RECARREGAR = '[Data Shared Services] FINANCEIRO Custo ABC',
    FINANCEIRO_CAIXA_BANCOS_RECARREGAR = '[Data Shared Services] FINANCEIRO Caixa/Bancos',
    FINANCEIRO_CONTA_CORRENTE_RECARREGAR = '[Data Shared Services] FINANCEIRO Conta Corrente',
    FINANCEIRO_PLANO_DE_CONTAS_RECARREGAR = '[Data Shared Services] FINANCEIRO Plano de Contas',
    FINANCEIRO_CR_RECARREGAR = '[Data Shared Services] FINANCEIRO CR',
    FINANCEIRO_CR_BX_RECARREGAR = '[Data Shared Services] FINANCEIRO CR BX',
    FINANCEIRO_CR_PREJUIZOS_RECARREGAR = '[Data Shared Services] FINANCEIRO CR PREJUIZOS',
    FINANCEIRO_CP_RECARREGAR = '[Data Shared Services] FINANCEIRO CP',
    FINANCEIRO_CP_BX_RECARREGAR = '[Data Shared Services] FINANCEIRO CP BX',
    FINANCEIRO_ITEM_LCTO_RECARREGAR = '[Data Shared Services] FINANCEIRO Item Lancamento',
    FINANCEIRO_CARTEIRA_RECARREGAR = '[Data Shared Services] FINANCEIRO Carteira',

    VENDAS_TIPO_DE_PAGAMENTO_RECARREGAR = '[Data Shared Services] VENDAS Forma de Pagamento',
    VENDAS_FATURA_ABERTAS_RECARREGAR = '[Data Shared Services] VENDAS Fatura Abertas',
    VENDAS_FATURA_PROCESSADAS_RECARREGAR = '[Data Shared Services] VENDAS Fatura Encerradas',
    VENDAS_FATURA_CANCELADAS_RECARREGAR = '[Data Shared Services] VENDAS Fatura Canceladas',

    VENDAS_ORDEM_ABERTAS_RECARREGAR = '[Data Shared Services] VENDAS Ordem Abertas',
    VENDAS_ORDEM_PROCESSADAS_RECARREGAR = '[Data Shared Services] VENDAS Ordem Encerradas',
    VENDAS_ORDEM_CANCELADAS_RECARREGAR = '[Data Shared Services] VENDAS Ordem Canceladas',

    VENDAS_PEDIDO_ABERTAS_RECARREGAR = '[Data Shared Services] VENDAS Pedido Abertos',
    VENDAS_PEDIDO_PROCESSADAS_RECARREGAR = '[Data Shared Services] VENDAS Pedido Encerrados',
    VENDAS_PEDIDO_CANCELADAS_RECARREGAR = '[Data Shared Services] VENDAS Pedido Cancelados',

    VENDAS_NOTA_SERVICO_PENDENTES_RECARREGAR = '[Data Shared Services] VENDAS Notas Servicos Abertas',
    VENDAS_NOTA_SERVICO_EMITIDAS_RECARREGAR = '[Data Shared Services] VENDAS Notas Servicos Encerradas',
    VENDAS_NOTA_SERVICO_CANCELADAS_RECARREGAR = '[Data Shared Services] VENDAS Notas Servicos Canceladas',

    VENDAS_NOTA_PRODUTO_PENDENTES_RECARREGAR = '[Data Shared Services] VENDAS Notas Produtos Abertas',
    VENDAS_NOTA_PRODUTO_EMITIDAS_RECARREGAR = '[Data Shared Services] VENDAS Notas Produtos Encerradas',
    VENDAS_NOTA_PRODUTO_CANCELADAS_RECARREGAR = '[Data Shared Services] VENDAS Notas Produtos Canceladas',

    ERRO = '[Data Shared Services] Erro',
}

export class CadastrosCentroDeCustoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.CADASTRO_CENTRO_DE_CUSTO_RECARREGAR;
}
export class CadastrosFilialRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.CADASTRO_FILIAL_RECARREGAR;
}
export class CadastrosColaboradorRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.CADASTRO_COLABORADOR_RECARREGAR;
}
export class CadastrosFornecedorRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.CADASTRO_FORNECEDOR_RECARREGAR;
}
export class CadastrosTipoDeFornecedorRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.CADASTRO_TIPO_FORNECEDOR_RECARREGAR;
}
export class CadastrosTipoDeContratoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.CADASTRO_TIPO_CONTRATO_RECARREGAR;
}
export class CadastrosFornecedorContratoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.CADASTRO_FORNECEDOR_CONTRATO_RECARREGAR;
}
export class CadastrosProdutoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.CADASTRO_PRODUTO_RECARREGAR;
}
export class CadastrosProjetoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.CADASTRO_PROJETO_RECARREGAR;
}
export class CadastrosGrupoDeProdutoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.CADASTRO_GRUPO_PRODUTO_RECARREGAR;
}
export class CadastrosCategoriaDeProdutoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.CADASTRO_CATEGORIA_PRODUTO_RECARREGAR;
}
export class CadastrosTipoDeProdutoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.CADASTRO_TIPO_PRODUTO_RECARREGAR;
}
export class CadastroCondicaoDePagamentoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.CADASTRO_CONDICAO_DE_PAGAMENTO_RECARREGAR;
}
export class CadastroCondicaoDePagamentoItemRecarregarAction extends NgRxAction<any> {
    type =
        DataSharedServicesActions.CADASTRO_CONDICAO_DE_PAGAMENTO_ITEM_RECARREGAR;
}
export class CadastroExportacaoDeRelatorioRecarregarAction extends NgRxAction<any> {
    type =
        DataSharedServicesActions.CADASTRO_EXPORTACAO_DE_RELATORIO_RECARREGAR;
}
export class CadastroExportacaoDeRelatorioItemRecarregarAction extends NgRxAction<any> {
    type =
        DataSharedServicesActions.CADASTRO_EXPORTACAO_DE_RELATORIO_ITEM_RECARREGAR;
}
export class CadastroMapeamentoDeRateioRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.CADASTRO_MAPEAMENTO_DE_RATEIO_RECARREGAR;
}

export class CadastroMapeamentoDeRateioItemRecarregarAction extends NgRxAction<any> {
    type =
        DataSharedServicesActions.CADASTRO_MAPEAMENTO_DE_RATEIO_ITEM_RECARREGAR;
}

export class ComprasFormaDePagamentoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.COMPRAS_TIPO_DE_PAGAMENTO_RECARREGAR;
}
export class ComprasRegraDeAprovacaoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.COMPRAS_REGRA_DE_APROVACAO_RECARREGAR;
}
export class ComprasTipoDeCompraRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.COMPRAS_TIPO_DE_COMPRA_RECARREGAR;
}
export class ComprasRequisicaoAbertasRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.COMPRAS_REQUISICAO_ABERTAS_RECARREGAR;
}
export class ComprasOrdemAbertasRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.COMPRAS_ORDEM_ABERTAS_RECARREGAR;
}
export class ComprasOrdemEncerradasRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.COMPRAS_ORDEM_ENCERRADAS_RECARREGAR;
}
export class ComprasCotacoesRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.COMPRAS_COTACOES_RECARREGAR;
}
export class ComprasPedidoAbertosRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.COMPRAS_PEDIDO_ABERTOS_RECARREGAR;
}
export class ComprasPedidoEncerradosRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.COMPRAS_PEDIDO_ENCERRADOS_RECARREGAR;
}
export class ComprasRecebimentoPendentesRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.COMPRAS_RECEBIMENTO_PENDENTES_RECARREGAR;
}
export class ComprasRecebimentoProcessadosRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.COMPRAS_RECEBIMENTO_PROCESSADOS_RECARREGAR;
}
export class ComprasOrdemSugestoesRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.COMPRAS_ORDEM_SUGESTOES_RECARREGAR;
}
export class ComprasOrdemProdutosRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.COMPRAS_ORDEM_PRODUTOS_RECARREGAR;
}
export class ComprasPlanejamentoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.COMPRAS_PLANEJAMENTO_RECARREGAR;
}
export class ComprasMotivoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.COMPRAS_MOTIVO_RECARREGAR;
}

export class ComprasNivelDeAprovacaoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.COMPRAS_NIVEL_DE_APROVACAO_RECARREGAR;
}

export class EstoqueAlmoxarifadoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.ESTOQUE_ALMOXARIFADO_RECARREGAR;
}
export class EstoqueMapeamentoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.ESTOQUE_MAPEAMENTO_RECARREGAR;
}
export class EstoqueSecaoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.ESTOQUE_SECAO_RECARREGAR;
}
export class EstoqueClassificacaoAuxiliarRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.ESTOQUE_CLASSIF_AUX_RECARREGAR;
}
export class EstoqueEntradaRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.ESTOQUE_ENTRADA_RECARREGAR;
}
export class EstoqueSaidaRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.ESTOQUE_SAIDA_RECARREGAR;
}
export class EstoqueTransferenciaRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.ESTOQUE_TRANSFERENCIA_RECARREGAR;
}
export class EstoqueSaldosRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.ESTOQUE_SALDOS_RECARREGAR;
}
export class EstoqueExpedicaoPendentesRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.ESTOQUE_EXPEDICAO_PENDENTES_RECARREGAR;
}
export class EstoqueExpedicaoProcessadosRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.ESTOQUE_EXPEDICAO_PROCESSADOS_RECARREGAR;
}
export class EstoqueMotivoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.ESTOQUE_MOTIVO_RECARREGAR;
}
export class EstoqueTipoDeRequisicaoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.ESTOQUE_TIPO_DE_REQUISICAO_RECARREGAR;
}
export class EstoqueNivelDeAprovacaoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.ESTOQUE_NIVEL_DE_APROVACAO_RECARREGAR;
}
export class EstoqueRegraDeAprovacaoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.ESTOQUE_REGRA_DE_APROVACAO_RECARREGAR;
}

export class CrmClienteRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.CRM_CLIENTE_RECARREGAR;
}
export class CrmCategoriaDeClienteRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.CRM_CATEGORIA_DE_CLIENTE_RECARREGAR;
}
export class CrmTipoDeClienteRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.CRM_TIPO_DE_CLIENTE_RECARREGAR;
}
export class CrmSituacaoDeClienteRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.CRM_SITUACAO_DE_CLIENTE_RECARREGAR;
}
export class CrmGrupoDeClienteRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.CRM_GRUPO_DE_CLIENTE_RECARREGAR;
}

export class FinanceiroFormaDePagamentoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.FINANCEIRO_TIPO_DE_PAGAMENTO_RECARREGAR;
}

export class FinanceiroClassificacaoAuxiliarRecarregarAction extends NgRxAction<any> {
    type =
        DataSharedServicesActions.FINANCEIRO_CLASSIFICACAO_AUXILIAR_RECARREGAR;
}
export class FinanceiroAgenciaRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.FINANCEIRO_AGENCIA_RECARREGAR;
}
export class FinanceiroCustoABCRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.FINANCEIRO_CUSTO_ABC_RECARREGAR;
}
export class FinanceiroCaixaBancosRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.FINANCEIRO_CAIXA_BANCOS_RECARREGAR;
}
export class FinanceiroContaCorrenteRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.FINANCEIRO_CONTA_CORRENTE_RECARREGAR;
}
export class FinanceiroPlanoDeContasRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.FINANCEIRO_PLANO_DE_CONTAS_RECARREGAR;
}
export class FinanceiroCrRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.FINANCEIRO_CR_RECARREGAR;
}
export class FinanceiroCrBxRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.FINANCEIRO_CR_BX_RECARREGAR;
}
export class FinanceiroCrPrejuizosRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.FINANCEIRO_CR_PREJUIZOS_RECARREGAR;
}
export class FinanceiroCpRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.FINANCEIRO_CP_RECARREGAR;
}
export class FinanceiroCpBxRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.FINANCEIRO_CP_BX_RECARREGAR;
}
export class FinanceiroItemLancamentoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.FINANCEIRO_ITEM_LCTO_RECARREGAR;
}
export class FinanceiroCarteiraRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.FINANCEIRO_CARTEIRA_RECARREGAR;
}

export class VendasFormaDePagamentoRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.VENDAS_TIPO_DE_PAGAMENTO_RECARREGAR;
}
export class VendasFaturaAbertasRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.VENDAS_FATURA_ABERTAS_RECARREGAR;
}
export class VendasFaturaProcessadasRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.VENDAS_FATURA_PROCESSADAS_RECARREGAR;
}
export class VendasFaturaCanceladasRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.VENDAS_FATURA_CANCELADAS_RECARREGAR;
}
export class VendasOrdemAbertasRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.VENDAS_ORDEM_ABERTAS_RECARREGAR;
}
export class VendasOrdemProcessadasRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.VENDAS_ORDEM_PROCESSADAS_RECARREGAR;
}
export class VendasOrdemCanceladasRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.VENDAS_ORDEM_CANCELADAS_RECARREGAR;
}

export class VendasPedidoAbertosRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.VENDAS_PEDIDO_ABERTAS_RECARREGAR;
}
export class VendasPedidoProcessadosRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.VENDAS_PEDIDO_PROCESSADAS_RECARREGAR;
}
export class VendasPedidoCanceladosRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.VENDAS_PEDIDO_CANCELADAS_RECARREGAR;
}

export class VendasNotaServicoPendentesRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.VENDAS_NOTA_SERVICO_PENDENTES_RECARREGAR;
}
export class VendasNotaServicoEmitidasRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.VENDAS_NOTA_SERVICO_EMITIDAS_RECARREGAR;
}
export class VendasNotaServicoCanceladasRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.VENDAS_NOTA_SERVICO_CANCELADAS_RECARREGAR;
}
export class VendasNotaProdutoPendentesRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.VENDAS_NOTA_PRODUTO_PENDENTES_RECARREGAR;
}
export class VendasNotaProdutoEmitidasRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.VENDAS_NOTA_PRODUTO_EMITIDAS_RECARREGAR;
}
export class VendasNotaProdutoCanceladasRecarregarAction extends NgRxAction<any> {
    type = DataSharedServicesActions.VENDAS_NOTA_PRODUTO_CANCELADAS_RECARREGAR;
}

export class ErroAction extends NgRxAction<any> {
    type = DataSharedServicesActions.ERRO;
}

export type DataSharedServicesAction =
    | CadastrosFilialRecarregarAction
    | CadastrosColaboradorRecarregarAction
    | CadastrosCentroDeCustoRecarregarAction
    | CadastroExportacaoDeRelatorioRecarregarAction
    | CadastroExportacaoDeRelatorioItemRecarregarAction
    | CadastrosFornecedorRecarregarAction
    | CadastrosTipoDeFornecedorRecarregarAction
    | CadastrosTipoDeContratoRecarregarAction
    | CadastrosFornecedorContratoRecarregarAction
    | CadastrosProdutoRecarregarAction
    | CadastrosProjetoRecarregarAction
    | CadastrosGrupoDeProdutoRecarregarAction
    | CadastrosCategoriaDeProdutoRecarregarAction
    | CadastrosTipoDeProdutoRecarregarAction
    | CadastroCondicaoDePagamentoRecarregarAction
    | CadastroCondicaoDePagamentoItemRecarregarAction
    | CadastroMapeamentoDeRateioRecarregarAction
    | CadastroMapeamentoDeRateioItemRecarregarAction
    | ComprasFormaDePagamentoRecarregarAction
    | ComprasCotacoesRecarregarAction
    | ComprasRegraDeAprovacaoRecarregarAction
    | ComprasTipoDeCompraRecarregarAction
    | ComprasRequisicaoAbertasRecarregarAction
    | ComprasOrdemAbertasRecarregarAction
    | ComprasOrdemEncerradasRecarregarAction
    | ComprasPedidoAbertosRecarregarAction
    | ComprasPedidoEncerradosRecarregarAction
    | ComprasRecebimentoPendentesRecarregarAction
    | ComprasRecebimentoProcessadosRecarregarAction
    | ComprasOrdemSugestoesRecarregarAction
    | ComprasOrdemProdutosRecarregarAction
    | ComprasPlanejamentoRecarregarAction
    | ComprasMotivoRecarregarAction
    | EstoqueAlmoxarifadoRecarregarAction
    | EstoqueMapeamentoRecarregarAction
    | EstoqueSecaoRecarregarAction
    | EstoqueClassificacaoAuxiliarRecarregarAction
    | EstoqueEntradaRecarregarAction
    | EstoqueSaidaRecarregarAction
    | EstoqueTransferenciaRecarregarAction
    | EstoqueSaldosRecarregarAction
    | EstoqueExpedicaoPendentesRecarregarAction
    | EstoqueExpedicaoProcessadosRecarregarAction
    | EstoqueMotivoRecarregarAction
    | EstoqueTipoDeRequisicaoRecarregarAction
    | EstoqueNivelDeAprovacaoRecarregarAction
    | EstoqueRegraDeAprovacaoRecarregarAction
    | CrmClienteRecarregarAction
    | CrmCategoriaDeClienteRecarregarAction
    | CrmTipoDeClienteRecarregarAction
    | CrmGrupoDeClienteRecarregarAction
    | CrmSituacaoDeClienteRecarregarAction
    | FinanceiroClassificacaoAuxiliarRecarregarAction
    | FinanceiroAgenciaRecarregarAction
    | FinanceiroCaixaBancosRecarregarAction
    | FinanceiroContaCorrenteRecarregarAction
    | FinanceiroPlanoDeContasRecarregarAction
    | FinanceiroFormaDePagamentoRecarregarAction
    | FinanceiroCrRecarregarAction
    | FinanceiroCrBxRecarregarAction
    | FinanceiroCrPrejuizosRecarregarAction
    | FinanceiroCpRecarregarAction
    | FinanceiroCpBxRecarregarAction
    | FinanceiroCustoABCRecarregarAction
    | FinanceiroItemLancamentoRecarregarAction
    | FinanceiroCarteiraRecarregarAction
    | VendasFormaDePagamentoRecarregarAction
    | VendasFaturaAbertasRecarregarAction
    | VendasFaturaProcessadasRecarregarAction
    | VendasFaturaCanceladasRecarregarAction
    | VendasOrdemAbertasRecarregarAction
    | VendasOrdemProcessadasRecarregarAction
    | VendasOrdemCanceladasRecarregarAction
    | VendasPedidoAbertosRecarregarAction
    | VendasPedidoProcessadosRecarregarAction
    | VendasPedidoCanceladosRecarregarAction
    | VendasNotaServicoPendentesRecarregarAction
    | VendasNotaServicoEmitidasRecarregarAction
    | VendasNotaServicoCanceladasRecarregarAction
    | VendasNotaProdutoPendentesRecarregarAction
    | VendasNotaProdutoEmitidasRecarregarAction
    | VendasNotaProdutoCanceladasRecarregarAction
    | ErroAction;
